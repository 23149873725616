function initModal( popup, gallery = false ){

    function showModal(popup){
        $("body").addClass("scroll-lock");
        $('.modal-overlay').addClass("visible");
        popup.addClass("visible");
        TweenLite.to( 
            popup, 0, { 
                ease:Power1.easeIn,
                opacity:0,
                top:-100,
                delay: 0, 
                onComplete: function(){}
        });

        TweenLite.to( 
            popup, 0.3, {
                ease:Quad.easeOut, 
                opacity:1, 
                top:0,  
                delay: 0, 
                onComplete: function(){}
        });	
    
    }

    function closeModal(popup){
        var button_close = popup.find('.modal-close');
        button_close.on("click", function(e){
            e.preventDefault();
            $('.main-header').show();
            TweenLite.to( 
                popup, 0.3, {
                    ease:Quad.easeIn, 
                    opacity:0, 
                    top:-100,
                    delay: 0, 
                    onComplete: function(){
                        $('.modal-overlay').removeClass("visible");
                        popup.removeClass("visible");
                        $("body").removeClass("scroll-lock");
                    }
                }
            );

        });
    }

    showModal(popup);
    closeModal(popup);
    
}

module.exports = { 
    initModal
};